.listOwnSetEntry {

    .pe {
        cursor: pointer;

        &:hover {
            color: var(--bs-primary);
            background-color: var(--bs-gray-200);
        };
    };

};