.emoticons {
  position: relative;

  &.active ul {
    display: block;
  }

  ul {
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    left: 1.5em;
    background-color: var(--bs-body-bg);
    display: none;

    li {
      list-style: none;
    }

  }
}