.headComponent {
    border-bottom: 1px solid var(--bs-primary);

    a {
        display: block;
        position: relative;
        cursor: pointer;

        &:after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 10;
        }

        svg {
            width: 24px;
            display: inline-block;
            position: relative;
            z-index: 1;
        }
    }

    img.badCode {
        height: 48px;
        width: auto;
        display: inline-block;
    }

    .logOut {
        width: 24px !important;
        height: 24px !important;
    }
}
