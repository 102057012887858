.App {
  position: relative;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-image: url('../public/bad-code-bg.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  background-color: var(--bs-body-bg);
  color: var(--bs-body-color);

  Icon {
    color: var(--bs-body-color);
  }

  svg {
    color: var(--bs-body-color);
  }

}