.infobox {
  position: relative;
  width: 24px;
  height: 24px;

  Icon {
    position: relative;
    width: 24px;
    height: 24px;
    z-index: 1;
  }

  .card {
    position: absolute;
    z-index: 20;
    width: 16rem;
  }
}