.votingUserList {
    border-left: 1px solid var(--bs-primary);

    .votingUser {
        border-bottom: 1px solid  var(--bs-primary);
    }

    span.averageValue{
        display: inline-flex;
        border: 1px solid var(--bs-primary);
        width: 3.5rem;
        height: 3.5rem;
        align-items: center;
        justify-content: center;
        color: var(--bs-primary);
        font-size: 1.5rem;
        font-weight: bold;
    }
}
