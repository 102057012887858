.animEmoticon {
    position: fixed;
    z-index: 10000;
    left: 0;
    top: 0;
    pointer-events: none;

    &.foreignTarget {
      animation: moveEmoticon .5s ease-in-out forwards;
    }

    &.selfTarget {
      left: 50%;
      top: 50%;
      transform: translate(-50% -50%);
      animation: expandEmoticon 1s ease-in-out forwards;

      svg {
        width: 100% !important;
        height: 100% !important;
      }

    }

}

@keyframes moveEmoticon {
  0% {
    transform: translate(var(--start-x), var(--start-y));
  }
  100% {
    transform: translate(var(--target-x), var(--target-y));
  }
}

@keyframes expandEmoticon {
  0% {
    width: 0;
    height: 0;
    left: 50%;
    top: 50%;
    opacity: 1;
  }
  100% {
    width: 100vw;
    height: 100vw;
    left: 0;
    top: 0;
    opacity: 0;
  }
}
