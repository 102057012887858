.pointButton {
    display: inline-block;
    position: relative;
    cursor: pointer;
    border: 1px solid var(--bs-primary);
    width: 3rem;
    height: auto;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */


    &:after {
        content: '';
        display: block;
        width: 100%;
        margin-top: 133%;
        z-index: 100;
    }

    .value {
        display: block;
        position: absolute;
        z-index: 10;
        left: 50%;
        top: 50%;
        translate: -50% -50%;
        color: var(--bs-primary);
    }

    &.selected {
        background-color: var(--bs-primary);

        .value {
            color: var(--bs-gray-100);
        }
    }
};