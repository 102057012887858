.votingUser {

  span {
    &.emptyVoting {
      display: inline-block;
      width: 3.5rem;
      position: relative;
      border: 1px solid var(--bs-primary);

      &:after{
        content: '';
        display: block;
        width: 100%;
        margin-top: 100%;
      }
    }

    &.value {
      display: inline-flex;
      border: 1px solid var(--bs-primary);
      width: 3.5rem;
      height: 3.5rem;
      align-items: center;
      justify-content: center;
      color: var(--bs-primary);
      font-size: 1.25rem;
    }

  }

}